<template>
    <v-app v-if="getLayoutWSD.wsd === true">
        <v-container fluid class="grey lighten-5 login pa-0">
            <v-row no-gutters justify="center" class="flex-nowrap">
                <img
                    style="width: 100%; height: 100%; position: absolute; opacity: 0.2;"
                    src="@/assets/img/wsd/bg_dashboard.jpg"
                    alt
                />
                <v-col
                    cols="12"
                    md="8"
                    lg="9"
                    sm="7"
                    class="login__image dashboard-WSD__content hidden-sm-and-down"
                >
                    <div class="dashboard-WSD__logo">
                        <img src="@/assets/img/wsd/logowsd.png" alt="Logo WSD" />
                    </div>
                    <div class="p-relative">
                        <img
                            style="width: 100%"
                            src="@/assets/img/wsd/dziecko.jpg"
                            alt="Hero image"
                        />
                        <p class="p-absolute d-flex flex-wrap justify-center dashboard-WSD__text">
                            <span style="font-weight: 700" class="py-5">MISJA SZPITALA</span>
                            Misją Szpitala jest zapewnienie nowoczesnej diagnostyki i kompleksowego, interdyscyplinarnego leczenia chorób wieku dziecięcego z zastosowaniem innowacyjnych technologii medycznych oraz prowadzenie profilaktyki i edukacji zdrowotnej.
                        </p>
                    </div>
                </v-col>
                <v-col cols="12" lg="3" md="5" sm="5">
                    <v-card class="login__panel elevation-20">
                        <div class="login__content">
                            <img src="@/assets/img/global/entelli_logo.png" alt="logo Entelli" />
                            <transition name="fade" v-if="userName">
                                <v-form class="login__form">
                                    <v-text-field
                                        color="#445d75"
                                        label="Login"
                                        class="login__input"
                                        v-model="form.login"
                                        :rules="[rules.required]"
                                        :error-messages="errors"
                                        @keyup.enter="tryLogin"
                                    ></v-text-field>
                                    <v-text-field
                                        class="login__input"
                                        label="Hasło"
                                        value="wqfasds"
                                        color="#445d75"
                                        :append-icon="
											show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
										"
                                        :type="show ? 'text' : 'password'"
                                        @click:append="show = !show"
                                        v-model="form.password"
                                        :rules="[rules.required]"
                                        @keyup.enter="tryLogin"
                                        :error-messages="errors"
                                    ></v-text-field>

                                    <div class="login__form__actions">
                                        <v-btn class="buttons ma-2" @click="resetValue()">Resetuj</v-btn>
                                        <v-btn
                                            class="buttons buttons--main ma-2"
                                            v-bind:disabled="
												form.login === '' ||
													form.password === ''
											"
                                            @click="tryLogin"
                                        >Zaloguj</v-btn>
                                    </div>
                                </v-form>
                            </transition>
                            <transition name="fade" v-else>
                                <div class="login__user">
                                    <h2 class="font-weight-light">
                                        Witaj
                                        {{ $store.state.profile.user.name }}
                                        {{ $store.state.profile.user.lastname }}
                                    </h2>
                                </div>
                            </transition>

                            <a
                                class="login__panel__footer font-weight-light caption"
                                href="https://www.biuromax-balcer.pl"
                                target="_blank"
                            >Powered by Biuromax-Balcer</a>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
    <v-app v-else>
        <v-container fluid class="grey lighten-5 login pa-0">
            <v-row no-gutters justify="center">
                <img
                    style="position: absolute; top: 0; left: 0; z-index: 0; width: 100%; height: 100vh"
                    src="@/assets/img/login/login-background.jpg"
                    alt
                />
                <v-col cols="8" md="6" lg="8" class="login__image hidden-sm-and-down">
                    <img :src="imgLogin" alt="Hero image" style="z-index: 1" />
                </v-col>
                <v-col class="center" cols="12" md="6" lg="4" sm="6">
                    <v-card class="login__panel elevation-20">
                        <div class="login__content">
                            <img src="@/assets/img/global/entelli_logo.png" alt="logo Entelli" />
                            <transition name="fade" v-if="userName">
                                <v-form class="login__form">
                                    <v-text-field
                                        color="#445d75"
                                        label="Login"
                                        class="login__input"
                                        v-model="form.login"
                                        :rules="[rules.required]"
                                        :error-messages="errors"
                                        @keyup.enter="tryLogin"
                                    ></v-text-field>
                                    <v-text-field
                                        class="login__input"
                                        label="Hasło"
                                        value="wqfasds"
                                        color="#445d75"
                                        :append-icon="
											show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
										"
                                        :type="show ? 'text' : 'password'"
                                        @click:append="show = !show"
                                        v-model="form.password"
                                        :rules="[rules.required]"
                                        @keyup.enter="tryLogin"
                                        :error-messages="errors"
                                    ></v-text-field>

                                    <div class="login__form__actions">
                                        <v-btn class="buttons ma-2" @click="resetValue()">Resetuj</v-btn>
                                        <v-btn
                                            class="buttons buttons--main ma-2"
                                            v-bind:disabled="
												form.login === '' ||
													form.password === ''
											"
                                            @click="tryLogin"
                                        >Zaloguj</v-btn>
                                    </div>
                                </v-form>
                            </transition>
                            <transition name="fade" v-else>
                                <div class="login__user">
                                    <h2 class="font-weight-light">
                                        Witaj
                                        {{ $store.state.profile.user.name }}
                                        {{ $store.state.profile.user.lastname }}
                                    </h2>
                                </div>
                            </transition>

                            <a
                                class="login__panel__footer font-weight-light caption"
                                href="https://www.biuromax-balcer.pl"
                                target="_blank"
                            >Powered by Biuromax-Balcer</a>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
    data: () => ({
        form: {
            login: "",
            password: "",
        },
        rules: {
            required: (value) => !!value || "To pole nie może być puste",
        },
        show: false,
        imgLogin: null,
        number: null,
        userName: true,
        errors: "",
    }),

    computed: {
        ...mapGetters(["getLayoutWSD"]),
    },

    methods: {
        ...mapActions(["startSessionTimer"]),
        // === CUSTOME IMAGE ON LEFT === //
        getImgUrl() {
            this.number = Math.floor(Math.random() * 13) + 1;
            this.imgLogin = require("@/assets/img/login/" +
                this.number +
                ".svg");
        },
        // === RESET VALUE === //
        resetValue() {
            this.form.login = "";
            this.form.password = "";
        },

        // === TRANSITION AND CHANGED CONTAINER === //
        HelloUser() {
            this.userName = !this.userName;
            setTimeout(() => {
                this.userName = !this.userName;
                this.$router.push("dashboard");
            }, 2000);
        },
        // === LOGING === //
        async tryLogin() {
            this.errors = "";
            await Vue.axios({
                method: "POST",
                url: "/login",
                data: {
                    ...this.form,
                },
                mode: "no-cors",
                withCredentials: true,
                credentials: "same-origin",
            }).then((response) => {
                if (response.data.done) {
                    this.resetValue();
                    this.errors = "";
                    this.$store.commit("setUser", response.data.user);

                    this.HelloUser();
                    //START SESSION TIMER
                    this.startSessionTimer({
                        cookie: response.data.session.cookie,
                    });
                } else {
                    this.errors = response.data.msg;
                }
            });
        },
    },

    // === PHOTO === //
    beforeMount() {
        this.getImgUrl();
    },
};
</script>
